
import { butter } from "../plugins/buttercms";

export default {
  async mounted() {
    if(!this.$i18n.getLocaleCookie()){
      await this.$i18n.setLocale('en-ca')
    }
  },
  beforeRouteEnter(to, from, next) {
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1.0");
    next();
  },
  head() {
    const nuxtI18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    /** used this canonical filter for $nuxtI18nHead to remove the nuxt automated existing SEO canonical tag to prevent duplication */
    nuxtI18nHead.link = nuxtI18nHead.link.filter(tag => tag.rel !== "canonical") || [];
    nuxtI18nHead.link.push({ rel: 'canonical', href: `${this.$config.baseURL}${this.$route.fullPath}`});
    return nuxtI18nHead;
  },
  data() {
    return {
      notificationSlides: [],
      showNotification: true,
    };
  },
  computed: {
    hideNotification() {
      return this.$store.state.global.hideAnnouncementBar;
    },
  },
  watch: {
    $route() {
      this.getNotifications();
    },
  },
  async fetch() {
    await this.getNotifications();
  },
  methods: {
    async getNotifications() {
      try {
        const response = await butter(this.$config).content.retrieve(
          ["announcement_bar"],
          {
            locale: this.$i18n.locale,
            timeout: 10000,
          }
        );

        this.notificationSlides =
          response.data.data["announcement_bar"][0]["announcement_bar_items"];
      } catch (error) {
        console.error(error);
      }
      }
  },
};
