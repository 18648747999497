
  export default {
    name: "cal-accounts-menu",
    data() {
      return {
        isOpen: false,
      };
    },
    computed:{ 
       async isAuthenticatedUser(){
       return this.$store.state.account.isAuthenticatedUser || false;
      },
    },
    methods: {
      toggleMenu(){
        return (this.isOpen = !this.isOpen);
      },
      async signOut() {
       if (this.isAuthenticatedUser) {
        await this.$store.dispatch("account/SIGN_OUT");
        this.$router.push(`/${this.$i18n.locale}/sign-in`);
       }
  },
       orders(){
        if(this.isAuthenticatedUser){
          let redirect =this.$route.fullPath.includes('account')?'orders':'/account/orders';
          this.$router.push(redirect);
        }
  },
    },
  };
  