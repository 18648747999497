
import NotFountPage from '../components/not-found.vue';

export default {
  name: "Error",
  components: {
    NotFountPage,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.error.statusCode === 404 &&  !window.location.href.includes(`/${this.$i18n.locale}/not-found`)) {
      this.$nuxt.error({ statusCode: 404, message: 'Page not found' });
      window.location.href = `/${this.$i18n.locale}/not-found`;
    }
  }
};
