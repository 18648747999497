export default function ({ route, redirect, app }) {
  Object.entries(route.query).forEach(([key, value]) => {
    if (key && key === "utm-campaign") {
      sessionStorage.setItem("utm-campaign", value);
    }
  });
  const locale = app.i18n.getLocaleCookie();
  const redirectMappings = [
    {
      from: `occasions/${route.path.split("occasions/")[1]}`,
      to: `occasion/${route.path.split("occasions/")[1]}`,
    },
    {
      from: "wedding-flowers/final-invoice-confirmation",
      to: "final-invoice-confirmation",
    },
    {
      from: `wedding-flowers/final-invoice-confirmation/?recordId=${route.query.recordId}`,
      to: `final-invoice-confirmation/?recordId=${route.query.recordId}`,
    },
    { from: "occasion/mothers-day", to: "collection" },
    { from: "occasion/mothers-day-pandora", to: "collection" },
    { from: "product/jolly-centrepiece-old", to: "product/jolly-centrepiece" }, //TODO: Remove after product not live anymore -Eli

    // Add more redirection mappings as needed
  ];
  const matchedRoute = redirectMappings.find(
    (mapping) =>
      `/${locale}/${mapping.from}` === route.path ||
      `/${mapping.from}` === route.path ||
      `/${locale}/${mapping.from}` === route.fullPath
  );
  if (matchedRoute) {
    redirect(`/${locale}/${matchedRoute.to}`);
  }
}
