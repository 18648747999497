
export default {
  name: "cal-drop-down",
  props: {
    menu: {
      type: Object,
      default: {},
    },
    defaultItem: {
      type: String,
      default: "",
    },
    pageData: {
      type: Object,
      default: new Object(),
    },
  },
  watch: {
    $route() {
      this.closeTheDropDown();
    },
  },
  created() {
    this.listItems = this.pageData[this.menu["sub_menu_list_name"]];
    this.$nuxt.$on("closeSubMenu", () => {
      this.closeTheDropDown();
    });
  },
  data() {
    return {
      listItems: [],
      selectedItem: {},
      showSelectorList: false,
      showSelectorListMob: false,
      showSlideOver: false,
    };
  },
  methods: {
    toggleDropdown(device) {
      this.highlightSelectedItem();
      if (device === "desktop") {
        this.showSelectorList = !this.showSelectorList;
      } else if (device === "mobile") {
        this.showSelectorListMob = !this.showSelectorListMob;
        this.showSlideOver = true;
      }
    },
    itemSelector(url, device = "desktop") {
      this.showSelectorList = false;
      this.routRedirect(url);
    },
    openDropDown() {
      this.showSelectorList = true;
      this.highlightSelectedItem();
    },
    closeTheDropDown() {
      this.showSelectorList = false;
    },
    highlightSelectedItem() {
      if (this.listItems) {
        const routerSplitter = this.$route.path.split("/");
        const chooseItem = routerSplitter[routerSplitter.length - 1];
        this.selectedItem =
          this.listItems.filter((item) => item.url.includes(chooseItem))[0] ||
          {};
      }
    },
    routRedirect(url) {
      if (url === "/wedding-flowers") {
        window.history.replaceState(
          null,
          null,
          `/${this.$i18n.locale}/wedding-flowers`
        );

        // Refresh the page without a full redirect
        location.reload();
      } else {
        window.location.href = url;
      }
    },
  },
};
