
import EventValidationMixin from "~/mixin/event-validation-mixin";
import calMainSlide from "./cal-main-slide.vue";
export default {
  components: { calMainSlide },
  name: "cal-main-carousel",
  mixins: [EventValidationMixin],
  props: {
    sliderTimer: {
      default: 5000,
      type: Number,
    },
    carouselType: {
      default: "banner",
      type: String,
    },
    slideType: {
      default: "herobanner",
      type: String,
    },
    slides: {
      default: [],
      type: Array,
      required: true,
    },
    wedding : {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      currentIndex: 0,
      slideSet: [],
      timeINterval: null,
    };
  },
  created() {
    this.slideSet = this.slides;
    this.timeINterval = setInterval(() => {
      this.next();
    }, this.sliderTimer);
  },
  methods: {
    next() {
      if (this.currentIndex < this.slideSet.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
      this.clearTimeInterval();
    },
    previous() {
      if (this.currentIndex < 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.slideSet.length - 1;
      }
      this.clearTimeInterval();
    },
    changeActiveIndex(index) {
      this.currentIndex = index;
      this.clearTimeInterval();
    },
    clearTimeInterval() {
      clearInterval(this.timeINterval);
      this.setTimeInterval();
    },
    setTimeInterval() {
      this.timeINterval = setInterval(() => {
        this.next();
      }, this.sliderTimer);
    },
  },
};
