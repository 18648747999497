export default async (context) => {
  let isAuthenticatedUser = await context.store.dispatch(
    "account/AUTHENTICATE_USER"
  );
  await context.store.dispatch("account/ADDRESSES");
  await context.store.dispatch("account/SET_USER_ADDRESS");
  if (!isAuthenticatedUser) {
    context.redirect(`/${context.i18n.loadedLanguages[0]}/sign-in`);
  }
};
