

  import { getCookieValueByName } from "~/utils/cookie";
  export default {
    name: "cal-footer-sub-section",
    props: ["direction"],
    data() {
      return {
        ddID: "xxxxxxx",
      }
    },
    methods: {
      /**
       * Copy text into clipboard
       */
      copyText() {
        navigator.clipboard.writeText(this.ddID)
      },
    },
    created() {
      let dataDogCookie = getCookieValueByName('_dd_s');
      const result = dataDogCookie ? dataDogCookie.match(new RegExp('id=' + "(.*)" + '&created')) : [];
      if (result && result.length != 0) {
        const ddSessionID = result[1];
        this.ddID = ddSessionID.slice(0, 8);
      }
    },
  }

