import { render, staticRenderFns } from "./cal-main-slide.vue?vue&type=template&id=34e77c8e"
import script from "./cal-main-slide.vue?vue&type=script&lang=js"
export * from "./cal-main-slide.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CalSignature: require('/app/components/cal-signature.vue').default})
