
  export default {
    name: "cal-signature",
    props: {
      signature: {
        default: "",
        type: String,
      },
    },
  }
