
import { localeList, getFlagByCode } from "@/utils/locale";

export default {
  name: "cal-local-change",
  data() {
    return {
      locales: localeList,
      isOpen: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    currentLocaleFlag() {
      return getFlagByCode(this.$i18n.locale);
    },
  },
  methods: {
    getFlagByCode,
    changeLocale() {
      return (this.isOpen = !this.isOpen);
    },
  },
};
